import { useEffect, useState } from 'react'
import css from '../../css/C0533/NewsInformation.module.css'
import { IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'
import { Link } from 'react-router-dom'



const NewsInformation = () => {

    const [newsdata, setNewsData] = useState<IC0553WebsiteImage[]>([])
    const [coure, setCoure] = useState<IC0553WebsiteImage[]>([]);
    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        loadNewsInfo();
        goGetDataFromDB();
    }, [])

    useEffect(() => {
        const closestDates = goUpdataNews(data);
        setNewsData(closestDates)
    }, [data])

    const goGetDataFromDB = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "home",
                item_section_code: "new_",
            });

            if (res.status === 200) {
                setData(res.data)
                console.log(data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getMatchImg = (coure: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = coure.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= coure.length) {
            return null;
        }

        console.log(indexID)

        const urlpath = coure[indexID].item_pic_url;
        const urldesc = coure[indexID].item_desc;
        const weburl = coure[indexID].item_link_url
        return <a href={weburl}><img src={urlpath} alt={urldesc} /></a>;
    };

    const getMatchTitile = (coure: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = coure.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= coure.length) {
            return null;
        }

        console.log(indexID)

        const urldesc = coure[indexID].item_desc;
        return <p className={css.SummerCourePTag}>{urldesc}</p>;
    };
    
    const getTitleText = (coure: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = coure.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= coure.length) {
            return null;
        }

        console.log(indexID)

        const urldesc = coure[indexID].item_title;
        return urldesc;
    };

    const goUpdataNews = (data: IC0553WebsiteImage[]): IC0553WebsiteImage[] => {
        const today = new Date();

        const sortedData = data
            .map(item => ({
                ...item,
                date: new Date(item.item_title.replace(/(\d{4})\.(\d{2})\.(\d{2})/, '$1-$2-$3'))
            }))
            .sort((a, b) => Math.abs(a.date.getTime() - today.getTime()) - Math.abs(b.date.getTime() - today.getTime()));

        console.log(sortedData)
        console.log("sortedData")
        // Select the top 7 closest dates
        return sortedData.slice(0, 7);
    }

    const loadNewsInfo = async () => {
        try {

            const CoureRes = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "home",
                item_section_code: "coure_"
            });

            if (CoureRes.status === 200) {
                setCoure(CoureRes.data)
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    const content = (
        <section className={css.MainSection}>
            <div className={css.MainDiv}>

                <div className={css.NewsDiv}>
                    <div className={css.NewsTitleDiv}>
                        <p className={css.NewsTitle}>News</p>
                    </div>
                    <table className={css.NewsDataTable}>
                        {newsdata.map((data) => (
                            <tr className={css.NewsDataTabletr}>
                                <div className={css.tablebox} style={{ color: `${data.item_color}` }}>
                                    <th className={css.NewsDataTitle}><p>{data.item_title}</p></th>
                                    <th className={css.NewsDataDesc} ><a href={data.item_link_url} style={{ color: `${data.item_color}` }}>{data.item_desc}</a></th>
                                </div>
                            </tr>
                        ))
                        }
                    </table>
                </div>

                <div className={css.VideosDiv}>
                    <iframe
                        className={css.Videos}
                        width="722"
                        height="430"
                        frameBorder='0'
                        src="https://www.youtube.com/embed/QQc04V4T2lI?mute=1&autoplay=1 "
                        title="Hong Kong Rope Skipping Union 香港跳繩精英訓練社"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
            </div>

            <div className={css.CourseDiv}>

                <div className={css.SummerCoureTitle}>
                    {getMatchTitile(coure, "coure_90")}
                </div>

                <div className={css.ThreeInOne}>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(coure, "coure_01")}
                    </div>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(coure, "coure_02")}
                    </div>

                    <div className={css.EachCourseImg}>
                        {getMatchImg(coure, "coure_03")}
                    </div>

                </div>
            </div>

            <div className={css.CourseDiv2}>

                <div className={css.TwoInOne}>

                    <div className={css.EachDescImg}>
                        {getMatchImg(coure, "coure_68")}
                    </div>

                    <div className={css.EachDescImg}>
                        {getMatchImg(coure, "coure_69")}
                    </div>

                </div>

                <div className={css.CourseDesc}>
                    <div className={css.SummerCoureTitle}>
                        <p className={css.SummerCourePTag}>{getTitleText(coure, "coure_91")}</p>
                    </div>
                    <div className={css.EachCourseImg2}>
                        {getMatchImg(coure, "coure_04")}
                    </div>
                </div>

            </div>

            {/* <div className={css.ClassInfo}>
                <div className={css.SummerCoure}>

                    <div className={css.SummerCoureSub1}>
                        <div className={css.SummerCoureTitle}>

                        </div>
                        <div className={css.SummerCoureImg}>
                            <img src={BookLet} alt="Booklet"></img>
                        </div>
                    </div>

                    <div className={css.SummerCoureSub1}>
                        <div className={css.SummerCoureTitle}>
                            <p className={css.SummerCourePTag}>跳繩暑期班課程</p>
                        </div>
                        <div className={css.SummerCoureImg}>
                            {getMatchImg(coure, "coure_01")}
                        </div>
                    </div>

                </div>
            </div>

            <div className={css.ClassInfo}>
                <div className={css.SummerCoure}>

                    <div className={css.SummerCoureSub1}>
                        <div className={css.SummerCoureTitle}>

                        </div>
                        <div className={css.SummerCoureImg}>
                            <img src={LevelBook} alt="Booklet"></img>
                        </div>
                    </div>

                    <div className={css.SummerCoureSub1}>
                        <div className={css.SummerCoureTitle}>
                            <p className={css.SummerCourePTag}>跳繩暑期班課程</p>
                        </div>
                        <div className={css.SummerCoureImg}>
                            {getMatchImg(coure, "coure_02")}
                        </div>
                    </div>

                </div> 
        </div>*/}

        </section >
    )
    return content
}

export default NewsInformation