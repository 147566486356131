import { useEffect, useState } from 'react'
import Select, { SingleValue } from "react-select";
import css from '../../css/C0533/Competition.module.css'

import image01 from '../../global/C533/BackgroundBG.jpg'
import imgPDF from '../../global/C533/PGFImg.png'
import axios from 'axios'
import { IC0553WebsiteImage } from '../../Interface/interface'

interface Option {
    value: string;
    label: string;
}


const Competition = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])
    const [chooseItem, setChooseItem] = useState<string | undefined>('2023')
    const [chooseItem2, setChooseItem2] = useState<string | undefined>('2024')
    const [options, setOptions] = useState<Option[]>([]);
    const [defaultOption, setDefaultOption] = useState<Option | null>(null); // State to hold default option

    // Custom styles for react-select
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: 'none', // Remove the border
            boxShadow: 'none', // Remove the box shadow
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#131e65', // Set text color to black
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: 'black', // Set text color for the options
            backgroundColor: state.isSelected ? '#f0f0f0' : 'white', // Customize background color on selection
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: 'black', // Customize placeholder color
        }),
    };

    const handleSelectChange = (selectedOption: SingleValue<Option>) => {
        if (selectedOption) {
            setChooseItem(selectedOption.value);
        }
    };

    const handleSelectChange2 = (selectedOption: SingleValue<Option>) => {
        if (selectedOption) {
            setChooseItem2(selectedOption.value);
        }
    };

    useEffect(() => {
        goGetDataJson();
        goGetCurrentYear();
        LoadYear();
    }, [])

    const LoadYear = () => {
        const currentYear = new Date().getFullYear();
        setChooseItem2(currentYear.toString());
        setChooseItem((currentYear - 1).toString());
    }

    useEffect(() => {
        if (options.length > 0) {
            const currentYearOption = options.find(option => option.label === '2024 成績公佈'); // Find 2024 option
            if (currentYearOption) {
                setDefaultOption(currentYearOption); // Set default option to 2024
            }
        }
    }, [options]);

    const goGetDataJson = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "Competition",
                item_section_code: "CPTI_",
            });
            if (res.status === 200) {
                setData(res.data);
                console.log(res.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const goGetCurrentYear = () => {
        const date = new Date();
        const year = date.getFullYear();
        const years: Option[] = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear + 1; i >= currentYear - 5; i--) {
            years.push({ value: i.toString(), label: i.toString() + " 成績公佈" });
        }
        console.log(years)
        setOptions(years);
    }

    const getMatchTitile = (coure: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = coure.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= coure.length) {
            return null;
        }

        console.log(indexID)

        const urldesc = coure[indexID].item_title;
        const urlLink = coure[indexID].item_link_url;
        return <a href={urlLink}>{urldesc}</a>;
    };

    const getPageTitile = (coure: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = coure.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= coure.length) {
            return null;
        }

        const urldesc = coure[indexID].item_desc;
        return urldesc;
    };

    const getMatchLink = (data: IC0553WebsiteImage[], SeachTitile: string, ShowTitle: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        const urlpath = data[indexID].item_link_url;
        const dataTitle = data[indexID].item_title;
        return <a href={urlpath}>{dataTitle}</a>;
    };

    const getMatchLinkPlus = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        console.log("SeachTitile : " + SeachTitile)
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)

        if (indexID < 0 || indexID >= data.length) {
            return null;
        }

        if (data[indexID].item_link_url === "" || data[indexID].item_link_url === null) {
            return null
        }

        console.log("Index ID : " + indexID)
        const urlpath = data[indexID].item_link_url;
        const desctitle = data[indexID].item_title;
        return <div className={css.EachLink}><p>連結： </p> <a href={urlpath} target="_blank" rel="noreferrer">{desctitle}</a></div>;
    }


    const content = (
        <section className={css.MainSection}>
            <div className={css.TitleDiv}>
                {/* <p>聯校盃</p>
                <p>全港跳繩競速錦標賽 2024</p> */}
                <p>{getPageTitile(data, "CPTI_92")}</p>
                <p>{getPageTitile(data, "CPTI_93")}</p>
            </div>
            <div className={css.BannerImg}>
                <img src={image01} alt="BG photo" />
            </div>
            <div className={css.parentContainer}>
                <div className={css.RegulationsTitle} id="Solo">
                    {/* <p>{getMatchTitile(data, "CPTI_90")}</p> */}
                    {getMatchTitile(data, "CPTI_90")}
                </div>
            </div>
            <div className={css.LinkAndFileDiv}>
                <div className={css.PDFimgDiv}>
                    <a href="http://www.hkrsu.com/download/lian-xiao-bei-quan-gang-tiao-sheng-jing-su-jin-biao-sai2024-ge-ren-.pdf"><img src={imgPDF} alt="PDF image" /></a>
                </div>
                <div className={css.Link1Div}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_20", "比賽證書申請")}
                </div>
            </div>
            <div className={css.Link3Div}>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_02", "比賽時間表")}
                </div>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_03", "報名表 下載")}
                </div>
                <div className={css.eachLinkDiv}>
                    <p>連結： </p> {getMatchLink(data, "CPTI_21", "比賽聲帶 下載")}
                </div>
            </div>

            <div className={css.parentContainer}>
                <div className={css.RegulationsTitle}>
                    <p>示範影片 :</p>
                </div>
            </div>

            <div className={css.videoDiv}>
                <iframe
                    id="pl4"
                    className={css.video}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    title="UnionCupMaster_Demo"
                    width="640"
                    height="360"
                    src="https://www.youtube-nocookie.com/embed/05wDC4HFYbk?playsinline=1&rel=1&autoplay=1&mute=1&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=1"
                />
            </div>

            <div className={css.result}>

                <div className={css.EachResult}>
                    <div className={css.ReasultTitle}>
                        {options.length > 0 &&
                            <Select
                                options={options}
                                onChange={handleSelectChange}
                                defaultValue={options[2]} // Set default option to 2024
                                styles={customStyles}
                            />
                        }
                        {/* <p>2023 成績公佈</p> */}
                    </div>
                    {/* <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_05", "比賽相片精華")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_06", "8歲或以下組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_07", "9-11歲組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_08", "12歲或以上組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_09", "個人總成績")}</div> */}
                    {(() => {
                        const link1 = getMatchLinkPlus(data, `CPTI_${chooseItem}01`);
                        const link2 = getMatchLinkPlus(data, `CPTI_${chooseItem}02`);
                        const link3 = getMatchLinkPlus(data, `CPTI_${chooseItem}03`);
                        const link4 = getMatchLinkPlus(data, `CPTI_${chooseItem}04`);
                        const link5 = getMatchLinkPlus(data, `CPTI_${chooseItem}05`);

                        // If all links are null, display "No this year's data"
                        if (!link1 && !link2 && !link3 && !link4 && !link5) {
                            return <p>沒有該年度資料</p>;
                        }

                        // Otherwise, render the available links
                        return (
                            <>
                                {link1 || null}
                                {link2 || null}
                                {link3 || null}
                                {link4 || null}
                                {link5 || null}
                            </>
                        );
                    })()}

                </div>

                <div className={css.EachResult}>
                    <div className={css.ReasultTitle}>
                        {options.length > 0 &&
                            <Select
                                options={options}
                                onChange={handleSelectChange2}
                                defaultValue={options[1]} // Set default option to 2024
                                styles={customStyles}
                            />
                        }
                        {/* <p>2024 成績公佈</p> */}
                    </div>
                    {/* <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_10", "比賽相片精華")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_11", "9歲或以下組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_12", "10-12歲組別成績")}</div>
                    <div className={css.EachLink}><p>連結： </p> {getMatchLink(data, "CPTI_13", "13歲或以上組別成績")}</div> */}
                    {(() => {
                        const link1 = getMatchLinkPlus(data, `CPTI_${chooseItem2}01`);
                        const link2 = getMatchLinkPlus(data, `CPTI_${chooseItem2}02`);
                        const link3 = getMatchLinkPlus(data, `CPTI_${chooseItem2}03`);
                        const link4 = getMatchLinkPlus(data, `CPTI_${chooseItem2}04`);
                        const link5 = getMatchLinkPlus(data, `CPTI_${chooseItem2}05`);

                        if (!link1 && !link2 && !link3 && !link4 && !link5) {
                            return <p>沒有該年度資料</p>;
                        }

                        return (
                            <>
                                {link1 || null}
                                {link2 || null}
                                {link3 || null}
                                {link4 || null}
                                {link5 || null}
                            </>
                        );
                    })()}

                </div>

            </div>

            <hr className={css.Line} />


            <div className={css.parentContainer} id="Team">
                <div className={css.RegulationsTitle}>
                    {/* <p>團體賽 - 比賽章程</p> */}
                    {getMatchTitile(data, "CPTI_91")}
                </div>
            </div>

            <div className={css.Regulations2Group}>

                <div className={css.EachRegulations2}>
                    <div className={css.Regulations2}>
                        <div className={css.Regulations2SubTitle}>
                            <p>示範影片 :</p>
                        </div>

                        <div className={css.videoDiv}>
                            <iframe
                                id="pl5"
                                className={css.video}
                                frameBorder="0"
                                allowFullScreen
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                title="聯校盃全港跳繩競速錦標賽 團體賽－示範片段"
                                width="640"
                                height="360"
                                src="https://www.youtube-nocookie.com/embed/IIZS2SgrlEA?playsinline=1&rel=1&autoplay=1&mute=1&enablejsapi=1&origin=http://www.hkrsu.com&widgetid=2"
                            />
                        </div>

                        <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_14", "比賽相片精華 2023")}</div>
                    </div>
                </div>

                <div className={css.EachRegulations2}>
                    <div className={css.PDFimgDiv}>
                        <img src={imgPDF} alt="PDF image" />
                    </div>

                    <div className={css.RegulationsTitle2}>
                        {getMatchTitile(data, "CPTI_94")}
                    </div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_15", "章程 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_16", "報名表 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_17", "4x30s 接力聲帶 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_18", "2x30s 接力聲帶 下載")}</div>
                    <div className={css.EachLink2}><p>連結： </p> {getMatchLink(data, "CPTI_19", "1min 項目聲帶 下載")}</div>
                </div>

            </div>

        </section>
    )
    return content
}

export default Competition